// ConfigContext with dynamic key support and specific extensions inferred
import { createContext, useContext } from 'react';

export type ConfigContextType = {
    // configLoading: boolean;
    acceptedFileExtensions: string[];
    videoExtensions?: string[];
    imageExtensions?: string[];
    audioExtensions?: string[];
    rawImageExtensions?: string[];
    documentExtensions?: string[];
    photoshopExtensions?: string[];
    [key: string]: string[] | boolean | undefined; // Dynamic key support
};

export const ConfigContext = createContext<ConfigContextType>({} as ConfigContextType);

export default function useConfigContext() {
    return useContext(ConfigContext);
}
