import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ConfigContext, ConfigContextType } from '@/Hooks/useConfigContext';

export const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
    const [config, setConfig] = useState<ConfigContextType | null>(null);

    useEffect(() => {
        axios
            .post(route('config'))
            .then((response) => {
                const data = response.data.config;

                // Map backend keys to frontend keys, adding defaults where necessary
                const dynamicConfig: ConfigContextType = Object.keys(data).reduce((acc, key) => {
                    const formattedKey = key.replace('_extensions', 'Extensions');
                    acc[formattedKey] = data[key] || [];
                    return acc;
                }, {} as ConfigContextType);

                setConfig({
                    ...dynamicConfig,
                    configLoading: false
                });
            })
            .catch(() => {
                setConfig({
                    configLoading: false,
                    acceptedFileExtensions: []
                });
            });
    }, []);

    if (!config) {
        return null;
    }

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
